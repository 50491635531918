.cashout-section .bet-winner,
.cashout-section .sale-label,
.cashout-section .bet-odds-value,
.cashout-section .indsats-value,
.cashout-section .system-label,
.cashout-section .total-prize-value {
    font-weight: 600;
}

.cashout-section .divider {
    padding: 0;
}
.cashout-section .bet-label {
    padding-bottom: 10px;
}
