@charset "UTF-8";
@font-face {
    font-family: 'Notification';
    src: url('/sport/fonts/notification.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'Roboto Condensed', sans-serif;
    outline: none;
    -webkit-overflow-scrolling: touch;
}

body {
    background: #fff;
    min-height: 100%;
    height: 100%;
    color: #333;
}
body::-webkit-scrollbar {
    width: 3px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid darkgrey;
}
body.without-scroll {
    position: fixed;
    overflow: hidden;
}

.tooltip.sidebar::before {
    z-index: 1000;
}

.tooltip.sidebar::after {
    width: 6px !important;
    height: 6px !important;
    top: calc(50% + 4px) !important;
    border: none !important;
    left: -3px !important;
}
.tooltip.sidebar::before,
.tooltip.big9::before {
    width: 0 !important;
    height: 0 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield !important;
}
.Grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.Grid-cell {
    -ms-flex: 1 1;
        flex: 1 1;
}

.Grid--flexCells > .Grid-cell {
    display: -ms-flexbox;
    display: flex;
}

.Grid--top {
    -ms-flex-align: start;
        align-items: flex-start;
}

.Grid--bottom {
    -ms-flex-align: end;
        align-items: flex-end;
}

.Grid--center {
    -ms-flex-align: center;
        align-items: center;
}

.Grid--justifyCenter {
    -ms-flex-pack: center;
        justify-content: center;
}

.Grid-cell--top {
    -ms-flex-item-align: start;
        align-self: flex-start;
}

.Grid-cell--bottom {
    -ms-flex-item-align: end;
        align-self: flex-end;
}

.Grid-cell--center {
    -ms-flex-item-align: center;
        align-self: center;
}

.Grid-cell--autoSize {
    -ms-flex: none;
        flex: none;
}

.Grid--fit > .Grid-cell {
    -ms-flex: 1 1;
        flex: 1 1;
}

.Grid--full > .Grid-cell {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
}

.Grid--1of2 > .Grid-cell {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
}

.Grid--1of3 > .Grid-cell {
    -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
}

.Grid--1of4 > .Grid-cell {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
}

@media (--break-sm) {
    .small-Grid--fit > .Grid-cell {
        -ms-flex: 1 1;
            flex: 1 1;
    }

    .small-Grid--full > .Grid-cell {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    }

    .small-Grid--1of2 > .Grid-cell {
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    }

    .small-Grid--1of3 > .Grid-cell {
        -ms-flex: 0 0 33.3333%;
            flex: 0 0 33.3333%;
    }

    .small-Grid--1of4 > .Grid-cell {
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    }
}

@media (--break-md) {
    .med-Grid--fit > .Grid-cell {
        -ms-flex: 1 1;
            flex: 1 1;
    }

    .med-Grid--full > .Grid-cell {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    }

    .med-Grid--1of2 > .Grid-cell {
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    }

    .med-Grid--1of3 > .Grid-cell {
        -ms-flex: 0 0 33.3333%;
            flex: 0 0 33.3333%;
    }

    .med-Grid--1of4 > .Grid-cell {
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    }
}

@media (--break-lg) {
    .large-Grid--fit > .Grid-cell {
        -ms-flex: 1 1;
            flex: 1 1;
    }

    .large-Grid--full > .Grid-cell {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    }

    .large-Grid--1of2 > .Grid-cell {
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    }

    .large-Grid--1of3 > .Grid-cell {
        -ms-flex: 0 0 33.3333%;
            flex: 0 0 33.3333%;
    }

    .large-Grid--1of4 > .Grid-cell {
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    }
}

.Grid--gutters {
    margin: -1em 0 1em -1em;
}

.Grid--gutters > .Grid-cell {
    padding: 1em 0 0 1em;
}

.Grid--guttersLg {
    margin: -1.5em 0 1.5em -1.5em;
}

.Grid--guttersLg > .Grid-cell {
    padding: 1.5em 0 0 1.5em;
}

.Grid--guttersXl {
    margin: -2em 0 2em -2em;
}

.Grid--guttersXl > .Grid-cell {
    padding: 2em 0 0 2em;
}

@media (--break-sm) {
    .small-Grid--gutters {
        margin: -1em 0 1em -1em;
    }

    .small-Grid--gutters > .Grid-cell {
        padding: 1em 0 0 1em;
    }

    .small-Grid--guttersLg {
        margin: -1.5em 0 1.5em -1.5em;
    }

    .small-Grid--guttersLg > .Grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .small-Grid--guttersXl {
        margin: -2em 0 2em -2em;
    }

    .small-Grid--guttersXl > .Grid-cell {
        padding: 2em 0 0 2em;
    }
}

@media (--break-md) {
    .med-Grid--gutters {
        margin: -1em 0 1em -1em;
    }

    .med-Grid--gutters > .Grid-cell {
        padding: 1em 0 0 1em;
    }

    .med-Grid--guttersLg {
        margin: -1.5em 0 1.5em -1.5em;
    }

    .med-Grid--guttersLg > .Grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .med-Grid--guttersXl {
        margin: -2em 0 2em -2em;
    }

    .med-Grid--guttersXl > .Grid-cell {
        padding: 2em 0 0 2em;
    }
}

@media (--break-lg) {
    .large-Grid--gutters {
        margin: -1em 0 1em -1em;
    }

    .large-Grid--gutters > .Grid-cell {
        padding: 1em 0 0 1em;
    }

    .large-Grid--guttersLg {
        margin: -1.5em 0 1.5em -1.5em;
    }

    .large-Grid--guttersLg > .Grid-cell {
        padding: 1.5em 0 0 1.5em;
    }

    .large-Grid--guttersXl {
        margin: -2em 0 2em -2em;
    }

    .large-Grid--guttersXl > .Grid-cell {
        padding: 2em 0 0 2em;
    }
}

.col-05 {
    width: 5%;
}

.col-1,
.col-10 {
    width: 10%;
}

.col-15 {
    width: 15%;
}

.col-2,
.col-20 {
    width: 20%;
}

.col-25 {
    width: 25%;
}

.col-3,
.col-30 {
    width: 30%;
}

.col-33 {
    width: 33.3%;
}

.col-35 {
    width: 35%;
}

.col-4,
.col-40 {
    width: 40%;
}

.col-45 {
    width: 45%;
}

.col-5,
.col-50 {
    width: 50%;
}

.col-55 {
    width: 55%;
}

.col-6,
.col-60 {
    width: 60%;
}

.col-65 {
    width: 65%;
}

.col-7,
.col-70 {
    width: 70%;
}

.col-75 {
    width: 75%;
}

.col-8,
.col-80 {
    width: 80%;
}

.col-85 {
    width: 85%;
}

.col-9,
.col-90 {
    width: 90%;
}

.col-95 {
    width: 95%;
}

.col-10,
.col-100 {
    width: 100%;
}

[class*='col-'] {
    float: left;
}

.row-right [class*='col-'] {
    float: right;
}

.row:after {
    content: '';
    clear: both;
    display: block;
}

.clearfix {
    clear: both;
}

.flex-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
        flex-flow: row;
}

.flex1 {
    -ms-flex: 1 1;
        flex: 1 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex4 {
    -ms-flex: 4 1;
        flex: 4 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex2 {
    -ms-flex: 2 1;
        flex: 2 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex3 {
    -ms-flex: 3 1;
        flex: 3 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex6 {
    -ms-flex: 6 1;
        flex: 6 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex7 {
    -ms-flex: 7 1;
        flex: 7 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

@media screen and (max-width: 450px) {
    .flex7 {
        max-width: 70%;
    }
}

.flex8 {
    -ms-flex: 8 1;
        flex: 8 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
    text-align: right;
}

.flex9 {
    -ms-flex: 9 1;
        flex: 9 1;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
    display: -ms-flexbox;
    display: flex;
}

.flex-column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
        flex-flow: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: start;
}

.flex-column .flex-item {
    width: 100%;
    min-width: 100%;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-item-align: center;
        align-self: center;
}

.flex-item-top {
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-item-align: start;
        align-self: flex-start;
}

.flex-item-bottom {
    -ms-flex-pack: end;
        justify-content: flex-end;
    -ms-flex-item-align: end;
        align-self: flex-end;
}

ul,
ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.three-dots {
    white-space: nowrap !important;
    overflow: hidden !important;
    -o-text-overflow: ellipsis !important;
       text-overflow: ellipsis !important;
    text-align: left;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.f9 {
    font-size: 9px;
}

.f12 {
    font-size: 12px;
}

.f13 {
    font-size: 13px;
}

.f14 {
    font-size: 14px;
}

.f16 {
    font-size: 16px;
}

.f22 {
    font-size: 22px;
}

.f24 {
    font-size: 24px;
}

.f28 {
    font-size: 28px;
}

.message {
    font-weight: bold;
    font-size: 14px;
    color: #444;
}

.red {
    color: #cc0000 !important;
}

.grean {
    color: #00cc00;
}

.yellow {
    color: #f77705;
}

.orange {
    color: #f77705 !important;
}

.blue {
    color: #1c3e60;
}

.grey {
    color: #777;
}

.white {
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}

.bold {
    font-weight: bold !important;
}

.bg-white {
    background: white;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.border-none {
    border: none !important;
}

.message.error {
    color: #cc0000;
}

.message.success {
    color: #00dd00;
}

.btn {
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    min-width: 40px;
    padding: 12px 7px;
    margin: 5px;
    color: #fff;
    border: none;
    background-color: #1c3e60;
    -webkit-transition-duration: 0.3s;
         -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
}

@media screen and (max-width: 420px) {
    .btn {
        margin: 3px;
    }
}

.btn:hover {
    cursor: pointer;
    opacity: 0.8;
}

.btn.orange-btn {
    background-color: #f77705;
    color: #fff;
    font-weight: 600;
}

.lnk {
    color: #1c3e60;
    text-decoration: underline;
    cursor: pointer;
}

.lnk.grey {
    color: #ccc;
}

.relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}

.just-info {
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    padding: 10px;
}

.notification {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.notification-message .message {
    color: #fff;
}

.notification-badge {
    width: 18px;
    height: 18px;
    background: #dd0000;
    color: #fff;
    font-size: 12px;
    position: absolute;
    margin-top: 0;
    text-align: center;
    line-height: 18px;
    border-radius: 10px;
    -webkit-box-shadow: -1px 2px 5px #333;
            box-shadow: -1px 2px 5px #333;
    top: 2px;
    right: 10px;
}

.navigation-badge {
    width: 12px;
    height: 12px;
    background: #dd0000;
    color: #fff;
    font-size: 9px;
    position: absolute;
    margin-top: 0;
    text-align: center;
    line-height: 12px;
    border-radius: 10px;
    -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.4);
            box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.4);
    top: 1px;
    margin-left: 11%;
}

.top-navigation-badge {
    background: #dd0000;
    font-size: 9px;
    display: inline-block;
    line-height: 15px;
    height: 14px;
    width: 14px;
    position: relative;
    top: -9px;
    left: -3px;
    border-radius: 8px;
}

.full-width {
    width: 100%;
}

.w150 {
    width: 150px;
}

.w300 {
    width: 300px;
}

.w400 {
    width: 400px;
}

.m0 {
    margin: 0;
}

.mb10 {
    margin-bottom: 10px;
}

.p2 {
    padding: 2px;
}

.pt4 {
    padding-top: 4px;
}

.pt8 {
    padding-top: 8px;
}

.p5 {
    padding: 5px !important;
}

.pv5 {
    padding: 5px 0;
}

.m10 {
    margin: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px !important;
}

.m20 {
    margin: 20px;
}

.m40 {
    margin: 40px;
}
.m40a {
    margin: 40px auto;
}

.p10 {
    padding: 10px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}

.pad-h30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pad-h15 {
    padding-left: 15px;
    padding-right: 15px;
}

.ph10 {
    padding: 0 10px;
}

.ph20 {
    padding: 0 20px;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15 {
    padding-top: 15px;
}

.pt20 {
    padding-top: 20px;
}

.pt30 {
    padding-top: 40px;
}

.pt40 {
    padding-top: 40px;
}

.pt50 {
    padding-top: 50px;
}

.pl10 {
    padding-left: 10px;
}

.pr10 {
    padding-right: 10px;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.pv10 {
    padding: 10px 0;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb40 {
    padding-bottom: 40px;
}

.pb80 {
    padding-bottom: 80px;
}

.m0a {
    margin: 0 auto;
}

.mt35 {
    margin-top: 35px !important;
}

.mh5 {
    margin: 0 5px;
}

.mh20 {
    margin: 0 20px;
}

.mh30 {
    margin: 0 30px;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mv5 {
    margin: 5px auto;
}

.mv10 {
    margin: 10px auto;
}

.mv15 {
    margin: 15px auto;
}

.mb10 {
    margin: 0 auto 10px;
}

.mv50 {
    margin: 50px auto;
}

.f32 {
    font-size: 32px;
}

.f42 {
    font-size: 42px;
}
.max-600 {
    max-width: 600px;
}

.blue-area {
    padding: 10px 20px;
    color: #fff;
    background: #1c3e60;
}

.bg-gray {
    background: #eee;
}

.gray-area {
    padding: 10px;
    background: #eee;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 7px;
    color: #333;
    font-size: 12px;
}

.grey-rectangle {
    padding: 10px;
    background: #eee;
    margin: 10px;
    color: #333;
    font-size: 12px;
}

.scroll-area {
    overflow-y: auto;
    height: 100%;
    position: relative;
    max-height: 500px;
    -ms-touch-action: pan-x;
        touch-action: pan-x;
}
.scroll-area * {
    -ms-touch-action: pan-x;
        touch-action: pan-x;
}

.small-info-text {
    color: #555;
    font-size: 12px;
}

.divider {
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

.blue-divider {
    border-bottom: 3px solid #1c3e60;
    padding: 5px;
}

.border-left {
    border-left: 1px solid #ccc;
    margin-left: -1px;
}

.border-right {
    border-right: 1px solid #ccc;
    margin-left: -1px;
}

.border-top-green {
    border-top: 3px solid #00cc00;
}

.border-top-blue {
    border-top: 3px solid #1c3e60;
}

.border-top-red {
    border-top: 3px solid #cc0000;
}

.border-top-grey {
    border-top: 3px solid #ccc;
}

.arrow-right {
}

.no-scrollbars {
    overflow: hidden;
}

.new-badge:after {
    content: 'nyt';
    padding: 3px 3px;
    font-size: 11px;
    font-weight: bold;
    margin-left: -5px;
    /*color: #fff;
  background: #cc0000;*/
    color: #cc0000;
    border-radius: 10px;
    top: -14px;
    position: relative;
    height: 10px;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(1, 1);
    }
    to {
        -webkit-transform: scale(1.5, 1.5);
    }
}

/* Standard syntax */
@keyframes zoom {
    from {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
    }
    to {
        -webkit-transform: scale(1.5, 1.5);
                transform: scale(1.5, 1.5);
    }
}

.zoom-animation {
    -webkit-animation: pulse 1000ms ease infinite;
            animation: pulse 1000ms ease infinite;
    position: absolute;
    z-index: 1;
    margin-top: 4px;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    55% {
        -webkit-transform: scale(2);
                transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(5);
                transform: scale(5);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    55% {
        -webkit-transform: scale(2);
                transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.flex-wrapper-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row;
        flex-flow: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.app.without-scroll {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    -webkit-overflow-scrolling: auto;
}

body:not(.desktop) .app.without-scroll .layout {
    visibility: hidden;
}

.app .ui-modal .banner-wrapper {
    height: auto;
    position: relative;
}

.app .geo-info {
    background-color: white;
    min-width: 60%;
    max-width: 80%;
    margin: 50px auto 0;
    -webkit-box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 25px 15px;
}

input {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.common-shadow-box {
    overflow: hidden;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.img-404 {
    display: block;
    margin: 0 auto;
    max-width: 90%;
    width: 500px;
}

.quick-login-popup {
    padding: 30px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.quick-login-popup .title {
    text-align: center;
    font-size: 18px;
    color: #545454;
    font-weight: 600;
    margin-bottom: 30px;
}

.quick-login-popup .info {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #878787;
}

.quick-login-popup .control-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.quick-login-popup .control-wrapper .button {
    margin-bottom: 10px;
}

.quick-login-popup .ecoui.button {
    font-size: 20px;
    text-transform: uppercase;
}
.sr-bb .sr-lmt-plus__segment.srm-momentum {
    z-index: 2;
}
.bage-text {
    width: auto;
    max-width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-size: 12px;
    line-height: 12px;
    border-radius: 4px;
    padding: 4px 8px;
    color: #016310;
    background: #eafeed;
    display: inline-block;
    letter-spacing: 0.1px;
}
.bage-text span {
    margin-left: 9px;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.bage-text span:before {
    content: '';
    height: 3px;
    width: 3px;
    border-radius: 100%;
    background: #016310;
    position: absolute;
    left: -6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
}
.bage-text span:first-child:before {
    display: none;
}
.bage-text span:first-child {
    margin-left: 0;
}
