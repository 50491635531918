.view-tabs > header {
    width: 100%;
    padding: 0 30px;
    height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.view-tabs.light > header {
    background: none;
    border-bottom: none;
}

.view-tabs .view-tab {
    height: 31px;
    float: left;
    line-height: 34px;
    text-align: center;
    font-size: 16px;
    color: #585858;
    font-weight: 700;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
       transition-duration: 0.3s;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid transparent;
}

.view-tabs.light .view-tab {
    height: 32px;
    line-height: 32px;
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.view-tabs.switcher {
    border: none;
    background: #eee;
}

.view-tabs.switcher > header {
    border: none;
    height: 35px;
}

.view-tabs.switcher .view-tab {
    background: #ddd;
    border: none;
    color: #777;
    border-bottom: 0;
    height: 45px;
    font-size: 16px;
}

.view-tabs .view-tab.active {
    color: #1c3e60;
    border-bottom: 2px solid #1c3e60;
}

.view-tabs.light .view-tab.active {
    color: #1c3e60;
}

.terminal .view-tabs .view-tab.active {
    border-bottom: 2px solid #f77705;
}

.view-tabs.switcher .view-tab.active {
    background: #1c3e60;
    border-bottom: 0px;
    color: #fff;
    border-radius: 6px;
}

.view-tabs.rendered-all-tabs .view-tab-content {
    display: none;
}

.view-tabs.rendered-all-tabs .view-tab-content.active {
    display: block;
}
