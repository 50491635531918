.indbetalingsgraense-page {
    width: 56%;
    margin: 0 auto;
}

.indbetalingsgraense-page .info {
    color: #696969;
    font-size: 15px;
}

.indbetalingsgraense-page .view-select {
    width: 150px;
}

.indbetalingsgraense-page .view-select .Select-placeholder {
    background: #fff;
    border: 1px solid #ddd;
    color: #696969;
    font-size: 17px;
    font-weight: 100;
}

.indbetalingsgraense-page .view-select .Select-value {
    background: #fff;
    border: 1px solid #ddd;
    color: #696969;
    border-radius: 5px;
}

.indbetalingsgraense-page .view-select .Select-value span {
    color: #696969 !important;
    font-size: 15px;
    font-weight: 100;
}

.indbetalingsgraense-page .view-select .Select-arrow {
    border-color: #696969 transparent transparent !important;
}

.indbetalingsgraense-page .select-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    width: 90%;
    margin-top: 15px;
}

.indbetalingsgraense-page .select-container .view-select label {
    display: none;
}

.indbetalingsgraense-page .view-text-input input {
    width: 100%;
    padding: 15px;
    background: #fff;
    border: 1px solid #ddd;
    color: #696969;
    font-size: 15px;
    font-weight: 100;
    text-align: left;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    margin-bottom: 5px;
}

.indbetalingsgraense-page .input-wrapper {
    width: 160px;
}

.indbetalingsgraense-page .fields-container label {
    display: none;
}

.indbetalingsgraense-page .fields-container .view-select .Select-value {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}

.indbetalingsgraense-page .fields-container {
    margin-top: 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.indbetalingsgraense-page .info-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    color: #696969;
    font-size: 15px;
}

.indbetalingsgraense-page.mobile {
    width: 95%;
    max-width: 350px;
}

.indbetalingsgraense-page.mobile .header {
    color: #696969;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 20px;
}

.indbetalingsgraense-page.mobile .view-button {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    display: block;
    padding: 15px;
    margin: 20px auto 0;
    width: 150px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    background: #f77705;
}
