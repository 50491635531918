.brugerinfo-page {
    width: 66%;
    margin: 0 auto;
    padding-bottom: 30px;
}

.brugerinfo-page .fields-container {
    margin-top: 20px;
}

.brugerinfo-page .fields-container .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    background: transparent;
}

.brugerinfo-page .fields-container .row > div {
    width: 46%;
}

.brugerinfo-page .fields-container .row::after {
    display: none;
}

.brugerinfo-page .fields-container .current-pass {
    width: 46%;
}

.brugerinfo-page .info {
    font-size: 11.5px;
    font-weight: 100;
    color: #131313;
}
.brugerinfo-page .info.red {
    color: red;
    margin-top: -15px;
}

.brugerinfo-page .view-button {
    width: 170px;
}

.brugerinfo-page .info a {
    text-decoration: none;
    color: #696969;
    font-weight: bold;
}

.brugerinfo-page .header {
    margin-bottom: 0 !important;
}

.brugerinfo-page .view-text-input input {
    height: 45px;
    width: 100%;
    padding: 10px 0 10px 20px;
    background: #fff;
    border: 1px solid #ddd;
    color: #696969;
    font-size: 14px;
    text-align: left;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    margin-bottom: 5px;
}

.brugerinfo-page .view-text-input label {
    font-size: 12px;
    text-align: left;
    color: #868686;
    font-weight: bold;
}

.brugerinfo-page .error.message {
    padding: 0;
    margin-top: 15px;
    text-align: left;
}

.brugerinfo-page.mobile {
    width: 90%;
    max-width: 700px;
}

.brugerinfo-page.mobile .header {
    color: #696969;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 20px;
}

.brugerinfo-page.mobile .view-button {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    display: block;
    padding: 15px;
    margin: 20px auto 0;
    width: 150px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 0px;
    background: #f77705;
}
