.casino-popup {
    color: white;
}

.casino-popup .view-popup-background {
    background: rgba(255, 255, 255, 0.79);
}

.casino-popup .view-popup-wrapper {
    border-radius: 0;
}

.casino-popup .view-popup-wrapper {
    background: url('/sport/img/live-casino/rod25_henvisning.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 740px;
    height: 420px;
}

.casino-popup .view-popup-wrapper .view-close-popup {
    background: #585858;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 26px;
    height: 26px;
    font-size: 18px;
    border-radius: 50%;
    right: 90px;
    top: 33px;
    padding: 0;
    cursor: pointer;
}

.casino-popup .view-popup-wrapper .view-close-popup > i {
    color: white;
}

.casino-popup .view-popup-content .content-container {
    width: 260px;
    margin-left: 100px;
    margin-top: 17px;
    height: 320px;
}

.casino-popup .view-popup-content .content-container > img {
    width: 100px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.casino-popup .content-container .header {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 13px;
}

.casino-popup .info {
    font-size: 17px;
    margin-bottom: 20px;
}

.casino-popup .button {
    text-transform: uppercase;
    background: #fbd103;
    color: #2f2f2f;
    padding: 17px;
    width: 125px;
    text-align: center;
    border-radius: 35px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    -webkit-box-shadow: 0px 18px 23px -5px rgba(0, 0, 0, 0.24);
            box-shadow: 0px 18px 23px -5px rgba(0, 0, 0, 0.24);
}
