.bonus-page {
    width: 50%;
    margin: 0 auto;
}

.bonus-page .header {
    color: #696969;
    font-weight: bold;
    margin-top: 45px;
    margin-bottom: 20px;
}

.bonus-page .bonus {
    color: #696969;
    font-size: 16px;
}

.bonus-page .usage-info {
    color: #da3232;
    font-size: 15px;
    margin-bottom: 30px;
}

.bonus-page .info {
    color: #696969;
    font-size: 15px;
    margin-bottom: 20px;
}

.bonus-page .buttons-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}

.bonus-page .buttons-container .view-button {
    margin: 0;
    padding: 13px;
    width: 100px;
    background: #1c3e60;
}

.bonus-page.mobile {
    width: 85%;
    max-width: 400px;
}

.bonus-page.mobile .header {
    margin-top: 20px;
    margin-bottom: 0;
}
