.sk-confirmation-wrapper {
    position: absolute;
    bottom: 0;
    max-width: 462px;
    width: 100%;
    z-index: 11;
    height: auto;
    max-height: 90vh;
    background-color: #edf1f8;
    border-radius: 5px;
    padding: 6px 18px 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.sk-confirmation-wrapper > hr {
    border: none;
    background-color: black;
    opacity: 0.3;
    border-radius: 5px;
    height: 5px;
    width: 60px;
    margin: 0 auto;
}

.sk-confirmation-content {
    margin-top: 16px;
}

.sk-confirmation-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: baseline;
        align-items: baseline;
    margin-bottom: 23px;
}

.sk-confirmation-header .close-btn-icon {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    background: #333;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
}

.sk-confirmation-header .close-btn-icon i {
    color: #eceff6;
    font-size: 20px;
}
@media screen and (max-width: 1023px) {
    .sk-confirmation-wrapper {
        margin: 7px;
        width: calc(100% - 14px);
    }
}
@media screen and (min-width: 1024px) {
    .sk-confirmation-wrapper {
        margin-bottom: 15px;
    }
}
