.view-overlay-loader {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 9999;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-flow: column;
        flex-flow: column;
}

.view-overlay-loader .label {
    text-align: center;
    color: #ddd;
    font-size: 24px;
}

.layout.terminal .view-overlay-loader .label {
    font-size: 64px;
    margin-top: 10px;
}
