.view-modal {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow-y: auto;
    background: #fff;
    -webkit-box-shadow: 0 0 45px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 45px rgba(0, 0, 0, 0.3);
}

.view-modal-wrapper {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    padding-top: 10px;
}

.desktop .view-modal-wrapper .view-modal .section-signup {
    min-width: 580px;
    min-height: 580px;
}

.view-modal-wrapper .view-modal {
    position: static;
    max-width: 800px;
    min-width: 300px;
    min-height: 300px;
    max-height: 80%;
    height: auto;
    width: auto;
}

.view-modal .view-page-toolbar {
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 2;
}

.desktop .view-modal {
    min-width: 700px;
}

.desktop .view-modal .view-page-toolbar {
    position: static;
    padding: 7px;
    height: 36px;
    line-height: 36px;
}

.desktop .view-modal .view-page-toolbar .title {
    color: #fff;
    font-size: 26px;
    text-align: center;
}

.desktop .view-modal .view-page-toolbar .close-btn {
    color: #fff;
    text-align: center;
    line-height: 41px;
    width: 40px;
}

.desktop .view-modal .close-btn > .close-btn-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -8px;
    top: -8px;
    z-index: 5;
    border-radius: 12px;
    background: white;
    cursor: pointer;
    color: #84939c;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
