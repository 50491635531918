.sk-dropdown-wrapper {
    position: fixed;
    bottom: 0;
    margin: 7px;
    max-width: 477px;
    width: calc(100% - 14px);
    z-index: 11;
    height: auto;
    max-height: 90vh;
    -webkit-transition: bottom 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    transition: bottom 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    -o-transition: transform 200ms ease-in-out, bottom 200ms ease-in-out;
    transition: transform 200ms ease-in-out, bottom 200ms ease-in-out;
    transition: transform 200ms ease-in-out, bottom 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
    background-color: #edf1f8;
    border-radius: 5px;
    padding: 6px 18px 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transform: translateY(150%);
        -ms-transform: translateY(150%);
            transform: translateY(150%);
}

.sk-dropdown-wrapper.open {
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
}

.sk-dropdown-wrapper > hr {
    border: none;
    background-color: black;
    opacity: 0.3;
    border-radius: 5px;
    height: 5px;
    width: 60px;
    margin: 0 auto;
}

.sk-dropdown-content {
    margin-top: 16px;
}

.sk-dropdown-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: baseline;
        align-items: baseline;
    margin-bottom: 23px;
}

.sk-dropdown-header .close-btn-icon {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    background: #333;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    cursor: pointer;
}

.sk-dropdown-header .close-btn-icon i {
    color: #eceff6;
    font-size: 20px;
}
@media screen and (max-width: 1023px) {
    .sk-dropdown-wrapper {
        left: 0;
    }
}
@media screen and (min-width: 1024px) {
    .sk-dropdown-wrapper {
        left: 50%;
        -webkit-transform: translate(-50%, 150%);
            -ms-transform: translate(-50%, 150%);
                transform: translate(-50%, 150%);
    }
    .sk-dropdown-wrapper.open {
        left: 50%;
        margin: 0 auto;
        -webkit-transform: translate(-50%, 0%);
            -ms-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
    }
}
