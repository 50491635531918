.confirm-popup .background-wrapper {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
}

.confirm-popup .confirm-popup-wrapper {
    position: fixed;
    z-index: 3;
    top: 50%;
    left: 50%;
    min-height: 25%;
    width: 80%;
    background: #fff;
    margin-left: -40%;
    margin-top: -40%;
    border-radius: 8px;
}

.confirm-popup .buttons-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-top: 1px solid #ccc;
}

.confirm-popup .buttons-wrapper .col-5 > *:first-child {
    border-left: 1px solid #ccc;
}

.confirm-popup .question-wrapper {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}

.confirm-popup .view-link-button {
    color: #1c3e60;
    min-width: 20px;
}

@media screen and (max-width: 320px) {
    .confirm-popup .confirm-popup-wrapper {
        min-height: 40%;
        margin-left: -45%;
        width: 90%;
    }
}
