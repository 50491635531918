.voucher-container {
    width: 55%;
    margin: 0 auto;
}

.mobile .voucher-container {
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.voucher-container .info {
    color: #696969;
    font-size: 15px;
    margin-bottom: 25px;
}

.voucher-container .view-text-input {
    height: 65px;
}

.voucher-container .view-text-input label {
    display: none;
}

.voucher-container .view-text-input input {
    width: 100%;
    padding: 10px 0 10px 20px;
    background: #fff;
    border: 1px solid #ddd;
    color: #696969;
    font-size: 14px;
    text-align: left;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    margin-bottom: 5px;
}

.voucher-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.voucher-container input[type='number'] {
    -moz-appearance: textfield;
}

.voucher-container .view-button {
    margin: 60px auto 0;
}
