.view-spinner {
    padding: 10px;
    width: auto;
    text-align: center;
    -ms-flex-flow: column;
        flex-flow: column;
}

.view-spinner i {
    color: #777;
}
.view-spinner i.white {
    color: white;
}
