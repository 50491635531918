.view-popup {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
        flex-flow: column;
    -ms-flex-pack: center;
        justify-content: center;
    z-index: 999;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}

.layout.blured {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    position: absolute;
}

.view-popup .view-close-popup {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 29px;
    padding: 0;
    text-align: center;
    background: #efefef;
    color: #333;
    position: absolute;
    top: -7px;
    right: -7px;
}

.view-popup.small .view-close-popup {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 36px;
    padding: 0px;
    position: relative;
    left: 50%;
    margin-left: 43%;
    margin-top: -27px;
    background: #1c3e60;
    color: #fff;
    border-radius: 20px;
    text-align: center;
}

.view-popup-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
}

.view-popup-wrapper {
    width: 600px;
    height: 709px;
    background: #fff;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin: 0px auto;
    border-radius: 50px;
}

.view-popup.small .view-popup-wrapper {
    width: 86%;
    height: 80%;
    border-radius: 8px;
}

.view-popup.small.h300 .view-popup-wrapper {
    width: 86%;
    height: 300px;
    border-radius: 8px;
}

.view-popup.small.h250 .view-popup-wrapper {
    width: 86%;
    height: 250px;
    border-radius: 8px;
}

.view-popup.small .view-popup-wrapper .full-height {
    border-radius: 8px;
}

.view-popup-wrapper h1 {
    margin-top: 80px;
    margin-bottom: 10px;
}

.view-popup .view-keyboard-popup-wrapper {
    width: 1000px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin: 0px auto;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
}

.view-popup .view-keyboard-popup-wrapper input {
    width: 900px;
    padding: 30px;
    font-size: 26px;
    margin: 20px auto;
}

.view-popup
    .view-keyboard-popup-wrapper.without-symbol-btn
    .keyboard-symbolButton,
.view-popup
    .view-keyboard-popup-wrapper.without-symbol-btn
    .keyboard-shiftButton {
    display: none;
}

.view-popup .view-keyboard-popup-wrapper .keyboard-button {
    font-family: Arial;
}

.view-popup .view-keyboard-popup-wrapper .keyboard-submit-button {
    background: #1c3e60;
}

.view-popup .view-keyboard-popup-wrapper .keyboard-clear-button {
    background: #ee3333;
    color: #fff;
}

/*.view-popup.small .view-close-popup {
  margin-left: 37%;
  margin-top: -55px;
}*/

.view-popup.small .view-popup-content {
    position: relative;
    height: 100%;
}

.view-popup .bottom-section {
    position: absolute;
    bottom: 20px;
    margin: 0px auto;
    padding: 0px;
    text-align: center;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .view-popup-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .view-popup .view-close-popup {
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 0px;
        margin: 0px;
        color: #fff;
        position: relative;
        left: 15px;
        top: 10px;
    }
}

.view-popup .android-start-screen p {
}
