.section-wrapper {
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    width: 100%;
}
.test-block {
    height: auto;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
}
.betslip-page,
.betslip-page-cont {
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    width: 100%;
    position: relative;
}
.betslip-page {
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}
.betslip-page-cont {
    height: 100vh;
    min-height: 100vh;
    padding-top: 48px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
.betslip-page-cont .content {
    height: auto;
    width: 100%;
}
.betslip-page-cont.isFixedButton {
    padding-bottom: 200px;
}
.betslip-page .multiple-coupon {
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 20px;
    background: #f0f2f8;
}

.betslip-page .dropdown {
    width: 96px;
    padding: 6px 20px 6px 13px;
    background: #333333;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.betslip-page .dropdown:hover {
    cursor: pointer;
}

.betslip-page .dropdown .arrow {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background: transparent;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    top: 8px;
    right: 7px;
    width: 8px;
    height: 8px;
}

.betslip-page .dropdown.active .arrow {
    top: 8px;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

.betslip-page .multiple-coupon header {
    background: #555;
    color: #fff;
    padding: 5px 20px;
    font-weight: 300;
    font-size: 14px;
}

.betslip-page .multiple-coupon header .row {
    width: 100%;
}

.betslip-page .multiple-coupon-fields {
    color: #333;
    padding: 15px 20px 0;
    font-size: 14px;
}
.betslip-page .multiple-coupon-fields .place-field {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    /*padding-left: 10px;*/
}

.betslip-page .multiple-coupon form {
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0;
}

.multiple-coupon-fields.disabled,
.multiple-coupon.disabled .row {
    opacity: 0.3;
    pointer-events: none;
}

.betslip-page .coupon-label {
    line-height: 26px;
}

.betslip-page .multi-gevins .left {
    text-align: left;
}

.betslip-page .multi-gevins .right {
    text-align: right;
}
.betslip-page .multi-gevins {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
}

.betslip-page .multiple-coupon-fields .gain {
    line-height: 26px;
    text-align: right;
}

.betslip-page .button-wrapper {
    padding: 20px 30px;
}

.betslip-page .button-wrapper.isFixedButton {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
}

.betslip-page .button-wrapper .layout .betslip-page .view-orange-button {
    width: 100%;
    padding: 12px 0;
    max-width: none;
}

.layout .betslip-page .view-odds-input input {
    float: right;
}

.layout .betslip-page .align-right {
    float: right;
}

.layout .betslip-page .free-bet-row span {
    display: block;
    color: grey;
    font-size: 12px;
}

.layout .betslip-page .green-btn {
    background: #18aa63;
}
.layout .betslip-page .extrabet-btn {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 13px 0;
}

.layout .betslip-page .WrapperFreeBetButton {
    height: auto;
    width: 100%;
    padding: 0 14px;
    margin: 10px 0 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.layout .betslip-page .WrapperFreeBetButton .view-button {
    height: auto;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: center;
        justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 20px 20px 20px 45px;
    text-align: left;
    border: 1px solid #f0f1f6;
    background: #f0f1f6;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    color: #333;
    font-weight: 900;
    position: relative;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    text-transform: none;
}
.layout .betslip-page .WrapperFreeBetButton .view-button:hover {
    color: #333 !important;
    opacity: 1;
    background: #f0f1f6;
}
.layout .betslip-page .WrapperFreeBetButton .view-button:before {
    content: '';
    height: 20px;
    width: 20px;
    border-radius: 100%;
    border: 1px solid #9198aa;
    background: #fff;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
.layout .betslip-page .WrapperFreeBetButton .view-button.green-btn {
    background: #f0f1f6;
    border: 1px solid #0bc628;
}
.layout .betslip-page .WrapperFreeBetButton .view-button.green-btn:before {
    background: #0bc628;
}
.layout .betslip-page .WrapperFreeBetButton .view-button.green-btn:after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    left: 22px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2;
}

.relative-container {
    position: relative;
}

.view-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10;
}
