.betslip-notification {
    position: fixed;
    height: 70px;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: rgba(248, 159, 0, 1);
    bottom: 55px;
    z-index: 2;
}

.betslip-notification .coupon-number {
    padding-left: 35px;
    line-height: 70px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    text-align: left;
}

.betslip-notification .coupon-number.custom-bet {
    padding-left: 35px;
    text-align: left;
}

.betslip-notification .coupon-number span,
.betslip-notification .coupon-label span {
    color: #fed300;
}

.betslip-notification .coupon-label {
    line-height: 70px;
    font-size: 20px;
    color: #fff;
    text-align: right;
    font-weight: bold;
    padding-right: 35px;
}

.betslip-notification .coupon-label.custom-bet {
    text-align: right;
    padding-right: 35px;
}
.betslip-notification .coupon-number .coupon-number-value {
    display: inline-block;
    color: #fed300;
    font-weight: bold;
    line-height: 20px;
}
