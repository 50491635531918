.view-button {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    width: 200px;
    display: block;
    padding: 7px 5px;
    margin: 2px auto;
    color: #fff;
    text-transform: uppercase;
    border: none;
    background-color: #1c3e60;
    -webkit-transition-duration: 0.3s;
         -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
    border-radius: 5px;
}

.view-button.active {
    cursor: pointer;
    background-color: #f77705;
}

.view-button.disabled {
    opacity: 0.2;
}

.view-button.blue {
    background: #334457;
}

.view-small-orange-button {
    cursor: pointer;
    position: relative;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    display: block;
    padding: 7px 10px;
    margin: 5px;
    float: left;
    color: #fff;
    text-transform: uppercase;
    border: none;
    background: #f77705;
}

.view-orange-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    display: block;
    padding: 7px 5px;
    margin: 5px auto;
    min-width: 100px;
    max-width: 200px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    background: #f77705;
}
.view-orange-button.disabled {
    opacity: 0.2;
}

.view-orange-button.small {
    min-width: 100px;
    max-width: 100px;
}

.view-red-button {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 7px 20px;
    margin: 5px auto;
    min-width: 200px;
    max-width: 200px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    -webkit-box-shadow: 0px 1px 0px #999;
            box-shadow: 0px 1px 0px #999;
    border-radius: 8px;
    background: rgba(214, 34, 34, 1);
    background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(214, 34, 34, 1)),
        color-stop(100%, rgba(150, 8, 8, 1))
    );
    background: -webkit-linear-gradient(
        top,
        rgba(214, 34, 34, 1) 0%,
        rgba(150, 8, 8, 1) 100%
    );
    background: -o-linear-gradient(
        top,
        rgba(214, 34, 34, 1) 0%,
        rgba(150, 8, 8, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top, left bottom,
        from(rgba(214, 34, 34, 1)),
        to(rgba(150, 8, 8, 1))
    );
    background: linear-gradient(
        to bottom,
        rgba(214, 34, 34, 1) 0%,
        rgba(150, 8, 8, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d62222', endColorstr='#960808', GradientType=0);
}

.view-gray-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 7px 5px;
    margin: 5px auto;
    min-width: 200px;
    max-width: 200px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    -webkit-box-shadow: 0px 1px 0px #999;
            box-shadow: 0px 1px 0px #999;
    background: rgba(55, 55, 55, 1);
}

.view-green-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 7px 5px;
    margin: 5px auto;
    min-width: 200px;
    max-width: 200px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    -webkit-box-shadow: 0px 1px 0px #999;
            box-shadow: 0px 1px 0px #999;
    border-radius: 8px;
    background: green;
}

.view-small-gray-button {
    cursor: pointer;
    position: relative;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    display: block;
    padding: 7px 10px;
    margin: 5px;
    float: left;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    background: rgba(135, 135, 135, 1);
}

.view-link-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 7px 20px;
    margin: 5px auto;
    min-width: 200px;
    max-width: 200px;
    color: #fff;
    text-transform: uppercase;
    border: none;
    background: none;
}

.view-white-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    display: block;
    padding: 7px 20px;
    margin: 5px auto;
    min-width: 200px;
    max-width: 200px;
    color: #333;
    text-transform: uppercase;
    border: none;
    background: #fff;
    /*box-shadow: 0px 1px 0px #999;*/
    border-radius: 8px;
}

.view-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

.row-buttons .view-link-button {
    color: #777;
}

.row-buttons .view-orange-button,
.row-buttons .view-link-button,
.row-buttons .view-button {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 100px;
    width: 200px;
    display: block;
    padding: 7px 5px;
    margin: 2px auto;
    text-transform: uppercase;
}

.view-round-button {
    border: 1px solid #aaa;
    width: 60px;
    height: 60px;
    color: #aaa;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    border-radius: 30px;
}

.view-round-button:active {
    background: #eee;
}
