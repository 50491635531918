.sk-create-group-button {
    position: fixed;
    bottom: 0px;
    top: auto;
    margin-bottom: 0;
    right: 5px;
    padding: 18px 25px;
    background-color: #edf1f8;
    border: 1px #c6ccdb solid;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    border-radius: 10px 10px 2px 10px;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
}

.sk-create-group-button.show-button {
    opacity: 1;
    margin-bottom: 70px;
    z-index: 3;
}

.sk-create-group-button svg {
    margin-right: 5px;
}

.sk-create-group-button.with-betslip {
    margin-bottom: 120px;
}

.sk-create-group-button.with-error {
    margin-bottom: 160px;
}
.sk-create-group-button.with-error p {
    color: #9198aa;
}

.confirmation-description {
    padding: 0 7px;
    margin: 25px auto;
}

.sk-select-wrapper {
    margin-top: 30px;
}

.sk-select-wrapper label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
}
.sk-select-wrapper .checkmark {
    display: -ms-flexbox;
    display: flex;
    margin-right: 9px;
    height: 24px;
    width: 24px;
    background: #edf1f8;
    border-radius: 3px;
    border: 1px solid #c6ccdb;
}

.sk-select-wrapper input:checked ~ .checkmark {
    background: #18aa1b;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .sk-create-group-button {
        right: 5px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .sk-create-group-button {
        right: 15px;
    }
}

@media screen and (min-width: 1024px) {
    .sk-create-group-button,
    .sk-create-group-button.with-betslip,
    .sk-create-group-button.with-error,
    .sk-create-group-button.show-button {
        margin-bottom: 0;
    }
    .sk-create-group-button {
        bottom: -40px;
        right: -2px;
    }
    .sk-create-group-button.with-betslip {
        bottom: 10px;
        right: -2px;
    }
    .sk-create-group-button:hover {
        cursor: pointer;
    }
    .sk-create-group-button.with-error {
        bottom: 50px;
    }
}
