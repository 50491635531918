.ui-modal {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow-y: scroll;
    background: #fff;
    -webkit-box-shadow: -3px 0px 10px #000;
            box-shadow: -3px 0px 10px #000;
    overflow-x: hidden;
}

.ui-modal .header {
    position: relative;
    color: #fff;
    padding: 7px;
    height: 70px;
    background-color: #1c3e60;
}

.ui-modal .header::before {
    content: '';
    color: #333;
    background-color: #1c3e60;
    position: absolute;
    height: 140px;
    border-bottom-right-radius: 500px 70px;
    border-bottom-left-radius: 500px 70px;
    width: 100%;
    bottom: -70px;
    left: 0;
    right: 0;
    z-index: -1;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transform-origin: center;
        -ms-transform-origin: center;
            transform-origin: center;
}

.ui-modal .header.noRound::before {
    height: 0;
}

.ui-modal .go-back {
    color: rgba(255, 255, 255, 0.548);
    line-height: 24px;
    font-size: 50px !important;
    position: relative;
    cursor: pointer;
    left: 12px;
}

.ui-modal .logo {
    position: relative;
    top: 25px;
}

.ui-modal-wrapper {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.ui-modal-wrapper .ui-modal {
    position: static;
    max-width: 800px;
    min-width: 300px;
    min-height: 300px;
    max-height: 700px;
    height: auto;
    width: auto;
}

.ui-modal .ui-modal-title {
    line-height: 50px;
    font-size: 18px;
    white-space: nowrap;
}

.ui-modal .close-btn {
    text-align: right;
    line-height: 34px;
    font-size: 22px;
    cursor: pointer;
}

.ui-modal .feedback-conteiner {
    -webkit-box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 28px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    margin: 20px;
    background: white;
}

.ui-modal .feedback-conteiner .view-button {
    font-size: 15px;
    font-weight: bold;
    border-radius: 0;
    padding: 20px 21px;
}

@media screen and (min-width: 1024px) {
    .ui-modal .header::before {
        bottom: -30px;
    }
}
