.feedback-conteiner {
    padding: 10px;
}

.feedback-conteiner .radio-group {
    max-width: 300px;
    margin: 0px auto;
}

.feedback-conteiner .radio-group input,
.feedback-conteiner .radio-group input::-webkit-outer-spin-button,
.feedback-conteiner .radio-group input::-webkit-inner-spin-button {
    appearance: auto !important;
    -webkit-appearance: radio !important;
    -moz-appearance: radio !important;
}

#text-answer {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}

.scrolly.bg-white .view-close-popup {
    color: #333;
}

.view-popup.scrolly.bg-white {
    position: absolute;
    height: auto;
}
