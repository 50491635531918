@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext');

* {
    outline: 0;
    padding: 0;
}

body {
    margin: 0;
}

.sr-bb .sr-poweredby__link {
    display: none;
}

.sr-bb {
    font-family: Roboto, Noto, Helvetica Neue, Helvetica, Arial, sans-serif;
    background: hsla(0, 0%, 100%, 0.68);
}

.sr-bb .sr-bb {
    background: none;
}

.sr-bb .srt-base-1 {
    background-color: transparent;
}

.sr-bb .srt-base-1,
.sr-bb .srt-base-1-is-active {
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active {
    background-color: rgba(36, 171, 226, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
    background-color: #e6e6e6;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
    background-color: rgba(36, 171, 226, 0.12);
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-home-1,
.sr-bb .srt-base-1-primary {
    background-color: transparent;
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-away-1 {
    background-color: transparent;
    color: #db541d;
    border-color: #db541d;
}

.sr-bb .srt-base-1-home-2 {
    background-color: transparent;
    color: #1c3e60;
    border-color: #156383;
}

.sr-bb .srt-base-1-away-2 {
    background-color: transparent;
    color: #db541d;
    border-color: #156383;
}

.sr-bb .srt-base-1-home-3 {
    color: #1c3e60;
}

.sr-bb .srt-base-1-away-3,
.sr-bb .srt-base-1-home-3 {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-away-3 {
    color: #db541d;
}

.sr-bb .srt-base-1-home-4 {
    background-color: transparent;
    color: #66c4eb;
    border-color: #66c4eb;
}

.sr-bb .srt-base-1-away-4 {
    background-color: transparent;
    color: #e68761;
    border-color: #e68761;
}

.sr-bb .srt-base-1-home-5 {
    background-color: transparent;
    color: #176f93;
    border-color: #176f93;
}

.sr-bb .srt-base-1-away-5 {
    background-color: transparent;
    color: #8e3713;
    border-color: #8e3713;
}

.sr-bb .srt-base-1-background {
    background-color: hsla(0, 0%, 100%, 0.9);
}

.sr-bb .srt-base-1-background,
.sr-bb .srt-base-2 {
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2 {
    background-color: #fff;
}

.sr-bb .srt-base-2-is-active,
.sr-bb .srt-base-2-is-hoverable:hover,
.sr-bb .srt-base-3 {
    background-color: #e5f5fc;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-active,
.sr-bb .srt-base-3-is-hoverable:hover {
    background-color: #dcf2fa;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-background {
    background-color: rgba(229, 245, 252, 0.9);
}

.sr-bb .srt-base-3-background,
.sr-bb .srt-base-4 {
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-4 {
    background-color: #f4fbfe;
}

.sr-bb .srt-base-5-is-active,
.sr-bb .srt-base-5-is-hoverable:hover {
    background-color: #c6e9f7;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-1 {
    background-color: #1c3e60;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-1-is-active,
.sr-bb .srt-primary-1-is-hoverable:hover {
    background-color: #1d89b5;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
    background-color: #31b0e4;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-2 {
    background-color: #1c3e60;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-3,
.sr-bb .srt-primary-4 {
    background-color: #19789e;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-5 {
    background-color: #71c8ec;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover,
.sr-bb .srt-primary-6,
.sr-bb .srt-primary-7,
.sr-bb .srt-primary-8 {
    background-color: #1c3e60;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .sr-match-header-horizontal__status-text {
    padding: 1px;
    margin-top: -2px;
}

.sr-bb .srt-primary-8-is-active-1 {
    background-color: #1d89b5;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
    background-color: #1d89b5;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-primary-9 {
    background-color: #bde6f6;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-10 {
    background-color: #50bce8;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-11 {
    background-color: #71c8ec;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-primary-12 {
    background-color: #1c3e60;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-base-1-primary-1,
.sr-bb .srt-base-1-primary-2 {
    background-color: transparent;
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-primary-3,
.sr-bb .srt-base-1-primary-4 {
    background-color: transparent;
    color: #19789e;
    border-color: #19789e;
}

.sr-bb .srt-base-1-primary-5 {
    background-color: transparent;
    color: #71c8ec;
    border-color: #71c8ec;
}

.sr-bb .srt-base-1-primary-6,
.sr-bb .srt-base-1-primary-7,
.sr-bb .srt-base-1-primary-8 {
    background-color: transparent;
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-primary-9 {
    background-color: transparent;
    color: #bde6f6;
    border-color: #bde6f6;
}

.sr-bb .srt-base-1-primary-10 {
    background-color: transparent;
    color: #50bce8;
    border-color: #50bce8;
}

.sr-bb .srt-base-1-primary-11 {
    background-color: transparent;
    color: #71c8ec;
    border-color: #71c8ec;
}

.sr-bb .srt-base-1-neutral-1 {
    background-color: transparent;
    color: #ccc;
    border-color: #ccc;
}

.sr-bb .srt-base-1-neutral-2 {
    background-color: transparent;
    color: #1b1b1b;
    border-color: #1b1b1b;
}

.sr-bb .srt-base-1-neutral-3 {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
    background-color: transparent;
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-neutral-5 {
    background-color: transparent;
    color: #404040;
    border-color: #404040;
}

.sr-bb .srt-base-1-neutral-6 {
    background-color: transparent;
    color: #c2c2c2;
    border-color: #c2c2c2;
}

.sr-bb .srt-base-1-neutral-7 {
    background-color: transparent;
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-neutral-8 {
    background-color: transparent;
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-neutral-9 {
    background-color: transparent;
    color: #f5f5f5;
    border-color: #f5f5f5;
}

.sr-bb .srt-base-1-neutral-10 {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-neutral-11 {
    background-color: transparent;
    color: #999;
    border-color: #999;
}

.sr-bb .srt-base-1-neutral-12 {
    background-color: transparent;
    color: #4d4d4d;
    border-color: #4d4d4d;
}

.sr-bb .srt-base-1-neutral-13 {
    background-color: transparent;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-base-1-is-active-home-1,
.sr-bb .srt-base-1-is-active-primary {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-is-active-away-1 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #db541d;
    border-color: #db541d;
}

.sr-bb .srt-base-1-is-active-home-2 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1c3e60;
    border-color: #156383;
}

.sr-bb .srt-base-1-is-active-away-2 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #db541d;
    border-color: #156383;
}

.sr-bb .srt-base-1-is-active-home-3 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1c3e60;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #db541d;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #66c4eb;
    border-color: #66c4eb;
}

.sr-bb .srt-base-1-is-active-away-4 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #e68761;
    border-color: #e68761;
}

.sr-bb .srt-base-1-is-active-home-5 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #176f93;
    border-color: #176f93;
}

.sr-bb .srt-base-1-is-active-away-5 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #8e3713;
    border-color: #8e3713;
}

.sr-bb .srt-base-1-is-active-primary-1,
.sr-bb .srt-base-1-is-active-primary-2 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-is-active-primary-3,
.sr-bb .srt-base-1-is-active-primary-4 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #19789e;
    border-color: #19789e;
}

.sr-bb .srt-base-1-is-active-primary-5 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #71c8ec;
    border-color: #71c8ec;
}

.sr-bb .srt-base-1-is-active-primary-6,
.sr-bb .srt-base-1-is-active-primary-7,
.sr-bb .srt-base-1-is-active-primary-8 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1c3e60;
    border-color: #1c3e60;
}

.sr-bb .srt-base-1-is-active-primary-9 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #bde6f6;
    border-color: #bde6f6;
}

.sr-bb .srt-base-1-is-active-primary-10 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #50bce8;
    border-color: #50bce8;
}

.sr-bb .srt-base-1-is-active-primary-11 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #71c8ec;
    border-color: #71c8ec;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #ccc;
    border-color: #ccc;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #1b1b1b;
    border-color: #1b1b1b;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
    background-color: rgba(36, 171, 226, 0.12);
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #404040;
    border-color: #404040;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #c2c2c2;
    border-color: #c2c2c2;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #595959;
    border-color: #595959;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #dedede;
    border-color: #dedede;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #f5f5f5;
    border-color: #f5f5f5;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
    background-color: rgba(36, 171, 226, 0.12);
    color: rgba(0, 0, 0, 0.12);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-11 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #999;
    border-color: #999;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #4d4d4d;
    border-color: #4d4d4d;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
    background-color: rgba(36, 171, 226, 0.12);
    color: #e6e6e6;
    border-color: #e6e6e6;
}

.sr-bb .srt-home-1 {
    background-color: #1c3e60;
    color: #fff;
    border-color: #1c3e60;
}

.sr-bb .srt-away-1 {
    background-color: #db541d;
    color: #fff;
    border-color: #db541d;
}

.sr-bb .srt-home-2 {
    background-color: #1c3e60;
    color: #fff;
    border-color: #156383;
}

.sr-bb .srt-away-2 {
    background-color: #db541d;
    color: #fff;
    border-color: #156383;
}

.sr-bb .srt-home-3 {
    background-color: #1c3e60;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-away-3 {
    background-color: #db541d;
    color: #fff;
    border-color: #fff;
}

.sr-bb .srt-home-4 {
    background-color: #66c4eb;
    color: #fff;
    border-color: #66c4eb;
}

.sr-bb .srt-away-4 {
    background-color: #e68761;
    color: #fff;
    border-color: #e68761;
}

.sr-bb .srt-home-5 {
    background-color: #176f93;
    color: #fff;
    border-color: #176f93;
}

.sr-bb .srt-away-5 {
    background-color: #8e3713;
    color: #fff;
    border-color: #8e3713;
}

.sr-bb .srt-neutral-1 {
    background-color: #ccc;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-2 {
    background-color: #1b1b1b;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-3 {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-4 {
    background-color: #595959;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-5 {
    background-color: #404040;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-6 {
    background-color: #c2c2c2;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-7 {
    background-color: #595959;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-8 {
    background-color: #dedede;
}

.sr-bb .srt-neutral-8,
.sr-bb .srt-neutral-9 {
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-9 {
    background-color: #f5f5f5;
}

.sr-bb .srt-neutral-10 {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-11 {
    background-color: #999;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-neutral-12 {
    background-color: #4d4d4d;
    color: #fff;
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-13 {
    background-color: #e6e6e6;
    color: rgba(0, 0, 0, 0.82);
    border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-win {
    background-color: #19789e;
}

.sr-bb .srt-draw,
.sr-bb .srt-win {
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-draw {
    background-color: #1c3e60;
}

.sr-bb .srt-lose {
    background-color: #98d8f1;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-text-secondary {
    opacity: 0.7;
}

.sr-bb .srt-text-disabled {
    opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
    opacity: 0.54;
}

.sr-bb .srt-icon {
    opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
    opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
    -webkit-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26),
        0 10px 20px 0 rgba(0, 0, 0, 0.19);
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.26),
        0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
    -webkit-box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
    -webkit-box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
    -webkit-box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
    -webkit-box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
    -webkit-box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
    -webkit-box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
            box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-soccer-yellow-card {
    fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
    stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
    stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
    stroke: #4fbe30;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
    stroke: #e43b3b;
    fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
    fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
    fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
    stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
    fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
    stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
    fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
    stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
    fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
    fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
    stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
    fill: #005100;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
    stroke: #005100;
}

.sr-bb .srt-fill-soccer-promotion-2 {
    fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
    stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
    fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
    stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-4 {
    fill: #1976d2;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
    stroke: #1976d2;
}

.sr-bb .srt-fill-soccer-promotion-5 {
    fill: #64b5f6;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
    stroke: #64b5f6;
}

.sr-bb .srt-nfl-timeout-1 {
    background-color: #f5a623;
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
    background-color: rgba(245, 166, 35, 0.3);
    color: #fff;
    border-color: hsla(0, 0%, 100%, 0.16);
}

.sr-bb .srt-nfl-penalty {
    background-color: #f4a621;
    color: #fff;
    border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1,
.sr-bb .srt-nfl-negative-yards-2 {
    background-color: #ac182e;
    color: #fff;
    border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
    background-color: #4b90de;
    color: #fff;
    border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
    background-color: #f5a623;
    color: #fff;
    border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
    fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
    stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
    fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
    stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
    fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
    stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
    fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
    stroke: #f5a623;
}

.sr-bb .srt-fill-change-increase {
    fill: #4fbe30;
}

.sr-bb .srt-stroke-change-increase {
    stroke: #4fbe30;
}

.sr-bb .srt-fill-change-decrease {
    fill: #e43b3b;
}

.sr-bb .srt-stroke-change-decrease {
    stroke: #e43b3b;
}

.sr-bb .srt-fill-text {
    fill: rgba(0, 0, 0, 0.82);
}

.sr-bb .srt-fill-text-invert {
    fill: #fff;
}

.sr-bb .srt-fill-text-secondary {
    fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
    fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
    fill: rgba(36, 171, 226, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
    stroke: rgba(36, 171, 226, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-base-1-active-2 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-base-1-primary {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
    stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
    fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
    stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
    fill: #fff;
}

.sr-bb .srt-stroke-base-2 {
    stroke: #fff;
}

.sr-bb .srt-fill-base-2-active {
    fill: #e5f5fc;
}

.sr-bb .srt-stroke-base-2-active {
    stroke: #e5f5fc;
}

.sr-bb .srt-fill-base-2-hover {
    fill: #e5f5fc;
}

.sr-bb .srt-stroke-base-2-hover {
    stroke: #e5f5fc;
}

.sr-bb .srt-fill-base-3 {
    fill: #e5f5fc;
}

.sr-bb .srt-stroke-base-3 {
    stroke: #e5f5fc;
}

.sr-bb .srt-fill-base-3-active {
    fill: #dcf2fa;
}

.sr-bb .srt-stroke-base-3-active {
    stroke: #dcf2fa;
}

.sr-bb .srt-fill-base-3-hover {
    fill: #dcf2fa;
}

.sr-bb .srt-stroke-base-3-hover {
    stroke: #dcf2fa;
}

.sr-bb .srt-fill-primary-1 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-1 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-primary-2 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-2 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-primary-3 {
    fill: #19789e;
}

.sr-bb .srt-stroke-primary-3 {
    stroke: #19789e;
}

.sr-bb .srt-fill-primary-4 {
    fill: #19789e;
}

.sr-bb .srt-stroke-primary-4 {
    stroke: #19789e;
}

.sr-bb .srt-fill-primary-5 {
    fill: #71c8ec;
}

.sr-bb .srt-stroke-primary-5 {
    stroke: #71c8ec;
}

.sr-bb .srt-fill-primary-6 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-6 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-primary-7 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-7 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-primary-8 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-8 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
    fill: #1d89b5;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
    stroke: #1d89b5;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
    fill: #1d89b5;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
    stroke: #1d89b5;
}

.sr-bb .srt-fill-primary-9 {
    fill: #bde6f6;
}

.sr-bb .srt-stroke-primary-9 {
    stroke: #bde6f6;
}

.sr-bb .srt-fill-primary-10 {
    fill: #50bce8;
}

.sr-bb .srt-stroke-primary-10 {
    stroke: #50bce8;
}

.sr-bb .srt-fill-primary-11 {
    fill: #71c8ec;
}

.sr-bb .srt-stroke-primary-11 {
    stroke: #71c8ec;
}

.sr-bb .srt-fill-primary-12 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-primary-12 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-home-1 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-home-1 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-home-2 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-home-2 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-home-3 {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-home-3 {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-home-4 {
    fill: #66c4eb;
}

.sr-bb .srt-stroke-home-4 {
    stroke: #66c4eb;
}

.sr-bb .srt-fill-home-5 {
    fill: #176f93;
}

.sr-bb .srt-stroke-home-5 {
    stroke: #176f93;
}

.sr-bb .srt-fill-away-1 {
    fill: #db541d;
}

.sr-bb .srt-stroke-away-1 {
    stroke: #db541d;
}

.sr-bb .srt-fill-away-2 {
    fill: #db541d;
}

.sr-bb .srt-stroke-away-2 {
    stroke: #db541d;
}

.sr-bb .srt-fill-away-3 {
    fill: #db541d;
}

.sr-bb .srt-stroke-away-3 {
    stroke: #db541d;
}

.sr-bb .srt-fill-away-4 {
    fill: #e68761;
}

.sr-bb .srt-stroke-away-4 {
    stroke: #e68761;
}

.sr-bb .srt-fill-away-5 {
    fill: #8e3713;
}

.sr-bb .srt-stroke-away-5 {
    stroke: #8e3713;
}

.sr-bb .srt-fill-neutral-1 {
    fill: #ccc;
}

.sr-bb .srt-stroke-neutral-1 {
    stroke: #ccc;
}

.sr-bb .srt-fill-neutral-2 {
    fill: #1b1b1b;
}

.sr-bb .srt-stroke-neutral-2 {
    stroke: #1b1b1b;
}

.sr-bb .srt-fill-neutral-3 {
    fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
    stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
    fill: #595959;
}

.sr-bb .srt-stroke-neutral-4 {
    stroke: #595959;
}

.sr-bb .srt-fill-neutral-5 {
    fill: #404040;
}

.sr-bb .srt-stroke-neutral-5 {
    stroke: #404040;
}

.sr-bb .srt-fill-neutral-6 {
    fill: #c2c2c2;
}

.sr-bb .srt-stroke-neutral-6 {
    stroke: #c2c2c2;
}

.sr-bb .srt-fill-neutral-7 {
    fill: #595959;
}

.sr-bb .srt-stroke-neutral-7 {
    stroke: #595959;
}

.sr-bb .srt-fill-neutral-8 {
    fill: #dedede;
}

.sr-bb .srt-stroke-neutral-8 {
    stroke: #dedede;
}

.sr-bb .srt-fill-neutral-9 {
    fill: #f5f5f5;
}

.sr-bb .srt-stroke-neutral-9 {
    stroke: #f5f5f5;
}

.sr-bb .srt-fill-neutral-10 {
    fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-10 {
    stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-11 {
    fill: #999;
}

.sr-bb .srt-stroke-neutral-11 {
    stroke: #999;
}

.sr-bb .srt-fill-neutral-12 {
    fill: #4d4d4d;
}

.sr-bb .srt-stroke-neutral-12 {
    stroke: #4d4d4d;
}

.sr-bb .srt-fill-neutral-13 {
    fill: #e6e6e6;
}

.sr-bb .srt-stroke-neutral-13 {
    stroke: #e6e6e6;
}

.sr-bb .srt-fill-win {
    fill: #19789e;
}

.sr-bb .srt-stroke-win {
    stroke: #19789e;
}

.sr-bb .srt-fill-draw {
    fill: #1c3e60;
}

.sr-bb .srt-stroke-draw {
    stroke: #1c3e60;
}

.sr-bb .srt-fill-lose {
    fill: #98d8f1;
}

.sr-bb .srt-stroke-lose {
    stroke: #98d8f1;
}

.sr-bb .srt-stop-base-1 {
    stop-color: transparent;
}

.sr-bb .srt-stop-primary-1,
.sr-bb .srt-stop-primary-2 {
    stop-color: #1c3e60;
}

.sr-bb .srt-stop-primary-3,
.sr-bb .srt-stop-primary-4 {
    stop-color: #19789e;
}

.sr-bb .srt-stop-primary-5 {
    stop-color: #71c8ec;
}

.sr-bb .srt-stop-primary-6,
.sr-bb .srt-stop-primary-7,
.sr-bb .srt-stop-primary-8 {
    stop-color: #1c3e60;
}

.sr-bb .srt-stop-primary-9 {
    stop-color: #bde6f6;
}

.sr-bb .srt-stop-primary-10 {
    stop-color: #50bce8;
}

.sr-bb .srt-stop-primary-11 {
    stop-color: #71c8ec;
}

.sr-bb .srt-stop-home-1,
.sr-bb .srt-stop-primary-12 {
    stop-color: #1c3e60;
}

.sr-bb .srt-stop-away-1 {
    stop-color: #db541d;
}

.sr-bb .srt-fill-neutral-14 {
    fill: #fff;
}

.sr-bb .srt-stroke-neutral-14 {
    stroke: #fff;
}

.sr-bb .srt-logo-powered-by-light {
    display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
    display: none;
}

.sr-lmt-logo {
    fill: rgb(36, 171, 226);
    border-radius: 5px;
}

.sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__crest {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 3px;
    width: 50px;
    height: 50px;
    background-color: rgba(241, 241, 241, 0.85);
    margin: auto;
}

.mobile
    .sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__crest {
    margin-top: 0;
    width: 30px;
    height: 30px;
}

.game-page
    .sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__crest {
    margin: 10px auto 0;
}

img.sr-crest-img.srt-fill-away-1.sr-match-header-horizontal__crest {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 3px;
    width: 25;
    height: 25;
    background-color: rgba(241, 241, 241, 0.85);
    margin: auto;
}

.game-page img.sr-crest-img.srt-fill-away-1.sr-match-header-horizontal__crest {
    margin: 0 15px;
}

img.sr-crest-img.srt-fill-home-1.sr-match-header-horizontal__crest {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 3px;
    width: 25px;
    height: 25px;
    background-color: rgba(241, 241, 241, 0.85);
    margin: auto;
}

.game-page img.sr-crest-img.srt-fill-home-1.sr-match-header-horizontal__crest {
    margin: 0 15px;
}

.sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__name {
    font-size: 14px;
    padding-top: 2px;
    margin: auto;
}

.game-page
    .sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__name {
    margin: 5px auto;
}

.sr-bb .sr-match-header-horizontal__container {
    display: -ms-flexbox;
    display: flex;
    padding: 0px 5px;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 0px;
}

.sr-bb .sr-match-header-horizontal__status-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.sr-match-header-horizontal.sr-small
    .sr-match-header-horizontal__container.srm-is-normal
    .sr-match-header-horizontal__team2 {
    display: block;
}

.sr-bb .sr-poweredby__wrapper {
    display: none;
}

.sr-bb .sr-match-header-horizontal__container {
    padding: 8px 0px;
}

.sr-bb .sr-soccer-lmtextended__lmt-wrap {
    padding: 0 0 10px 0;
}

.sr-bb .srt-lose {
    background-color: #d04f4f;
}

.sr-bb .srt-win {
    background-color: #60d16b;
}

.sr-lmt-0-ms-logo__wrapper img {
    -o-object-position: center !important;
       object-position: center !important;
    -o-object-fit: contain !important;
       object-fit: contain !important;
}

/* .sr-bb .sr-lmt-logo__img{
    max-width: 50px !important;
} */

@media screen and (max-width: 480px) {
    .sr-bb .sr-lmt-logo__img {
        max-width: 60px !important;
    }
}

/*
@media screen and (max-width: 370px) and (min-width: 354px) {
    .game-page .sport-radar-widget {
        height:330px !important;
    }
}
@media screen and (max-width: 390px) and (min-width: 371px) {
    .game-page .sport-radar-widget {
        height:340px !important;
    }
}
@media screen and (max-width: 500px) and (min-width: 391px) {
    .game-page .sport-radar-widget {
        height:380px !important;
    }
}

@media screen and (max-width: 955px) and (min-width: 940px) {
    .game-page .sport-radar-widget {
        height:330px !important;
    }
}

@media screen and (max-width: 985px) and (min-width: 955px) {
    .game-page .sport-radar-widget {
        height:345px !important;
    }
}

@media screen and (max-width: 1000px) and (min-width: 985px) {
    .game-page .sport-radar-widget {
        height:355px !important;
    }
}

@media screen and (max-width: 1035px) and (min-width: 1000px) {
    .game-page .sport-radar-widget {
        height:380px !important;
    }
}

@media screen and (max-width: 1085px) and (min-width: 1035px) {
    .game-page .sport-radar-widget {
        height:425px !important;
    }
}

@media screen and (max-width: 1165px) and (min-width: 1085px) {
    .game-page .sport-radar-widget {
        height: 440px !important;
    }
}

@media screen and  (min-width: 1165px) {
    .game-page .sport-radar-widget {
        height: 450px !important;
    }
}
*/
.game-page .widget-container {
    border-radius: 5px;
}
.game-page .widget-container .disabled {
    display: none !important;
}

.game-page .widget-container .widget-header {
    font-family: 'Roboto Condensed';
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -ms-flex-align: center;
        align-items: center;
    color: rgb(88, 88, 88);
    height: 45px;
}

.game-page .widget-container .widget-header .active {
    color: #1c3e60;
}

.game-page .widget-container .widget-header > div {
    width: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.game-page .widget-container .widget-header img {
    height: 20px;
    padding-right: 5px;
}
